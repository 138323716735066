import { keyframes, styled } from 'styled-components';

import { Box } from 'components/Box/Box';
import { colors } from 'theme/theme';

const whoosh = keyframes`
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const SearchLoadingSectionLine = styled(Box).attrs((props) => ({
  borderRadius: props.borderRadius || '3px',
}))`
  position: relative;
  overflow: hidden;
  -webkit-mask-image: radial-gradient(white, black);

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      to right,
      ${colors.selectionGrey} 0%,
      ${colors.calloutGrey} 50%,
      ${colors.selectionGrey} 100%
    );
    background-size: 50% 100%;
    animation: ${whoosh} 1s linear infinite;
  }
`;
